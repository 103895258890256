import { graphql } from "gatsby";
import React from "react";
import dayjs from "dayjs";
import "dayjs/locale/it";
import Layout from "../components/layout";
// import Share from "../components/share";
// import { createNotiziaPath } from "../utility/entity";
import Seo from "../components/seo";

const NotiziaTemplate = ({ data: { contentfulNews }, location }) => {
  const hero = [
    {
      className: "hero--news",
      title: contentfulNews.titolo,
    },
  ];
  const breadcrumb = [
    { title: "Home", link: "/" },
    { title: "Archivio News", link: "/archivio-news/" },
    { title: contentfulNews.titolo },
  ];

  // const eurl = createNotiziaPath(contentfulNews);
  const djsDate = dayjs(contentfulNews.data).locale("it");

  return (
    <Layout location={location} hero={hero} breadcrumb={breadcrumb}>
      <Seo
        title={contentfulNews.titolo}
        type="article"
        description={contentfulNews.abstract ? contentfulNews.abstract.abstract : ""}
        image={contentfulNews.immagineQuadrata}
        seo={contentfulNews.seo}
      />

      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
              <article className="news">
                <div className="row">
                  <div className="col-8 col-lg-8 mb-3">
                    <div className="news__date">{djsDate.format("D MMM YYYY")}</div>
                  </div>
                  {/* <div className="col-4 col-lg-4 text-right mb-3">
                    <Share url={eurl} className="news__share" />
                  </div> */}
                </div>
                {contentfulNews.abstract && (
                  <div className="row">
                    <div className="col-12">
                      <h1 className="news__claim">{contentfulNews.abstract.abstract}</h1>
                    </div>
                  </div>
                )}
                <div
                  className="news__text"
                  dangerouslySetInnerHTML={{
                    __html: contentfulNews.descrizione.childMarkdownRemark.html,
                  }}
                />
              </article>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotiziaTemplate;

export const pageQuery = graphql`
  query NotiziaDetailQuery($id: String!) {
    contentfulNews(id: { eq: $id }) {
      id
      titolo
      data
      friendlyUrl
      abstract {
        abstract
      }
      descrizione {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
